.my-swal {
  z-index: 10000;
}
.border {
  border-radius: 30px;
}

.swal2-container.swal2-backdrop-show,
.swal2-container.swal2-noanimation {

  z-index: 11000;
}